import React, { InputHTMLAttributes, forwardRef, useState } from "react";
import Icon from "../icons/icon";
import Close from "../icons/lib/close";
import Search from "../icons/lib/search";

export type SearchBarProps = {
  inputClassName?: string;
  onFocusChange?: (focused: boolean) => void;
  onSearchClick?: () => void;
  onClear?: () => void;
  onEscapePressed?: () => void;
} & InputHTMLAttributes<HTMLInputElement>;

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      inputClassName,
      className,
      onFocusChange,
      onSearchClick,
      onClear,
      onEscapePressed,
      onKeyDown,
      ...props
    }: SearchBarProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleClear = () => {
      onClear?.();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Escape") {
        onEscapePressed?.();
        return;
      }
      onKeyDown?.(e);
    };

    return (
      <div
        className={`ml-24 mr-24 border border-solid border-white flex items-center justify-center h-[42px] rounded-full bg-white font-open-sans w-full transition-all ${
          isFocused ? "bg-opacity-100 border-borderBlack" : "bg-opacity-0"
        } ${className}`}
      >
        <button
          id="navbar-search-button"
          type="button"
          className={`absolute left-24 pl-4 pr-4 rounded-l-full h-full flex items-center transition-all ${
            isFocused ? "bg-green" : ""
          }`}
          onClick={onSearchClick}
        >
          <Icon
            icon={Search}
            className={`transition-all ${
              isFocused ? "fill-black" : "fill-white"
            }`}
          />
        </button>

        <input
          ref={ref}
          id="navbar-search-input"
          type="text"
          placeholder="What are you looking for?"
          className={`pl-[55px] bg-transparent border-none flex-1 outline-none focus:ring-0 focus:ring-offset-0 transition-all w-auto ${
            isFocused
              ? "placeholder:text-black text-black"
              : "placeholder:text-white text-white"
          } ${inputClassName}`}
          onFocus={(e) => {
            setIsFocused(true);
            onFocusChange?.(true);
            props.onFocus?.(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            onFocusChange?.(false);
            props.onBlur?.(e);
          }}
          onKeyDown={handleKeyDown}
          {...props}
        />

        {/* Clear button */}
        {(props.value?.toString()?.length ?? 0) > 0 && (
          <button type="button" className="px-4" onClick={handleClear}>
            <Icon
              icon={Close}
              className={isFocused ? "fill-black" : "fill-white"}
              size={23}
            />
          </button>
        )}
      </div>
    );
  }
);

SearchBar.displayName = "SearchBar";

export default SearchBar;
