import GridFill from "../components/icons/lib/grid-fill";
import Headphones from "../components/icons/lib/headphones";
import KeyIcon from "../components/icons/lib/key";
import Note from "../components/icons/lib/note";
import Star from "../components/icons/lib/star";
import Track from "../components/icons/lib/track";
import User from "../components/icons/lib/user";
import Song from "../components/icons/lib/song";
import Soundkits from "../components/icons/lib/soundkits";
import { FilterType } from "../models/enums";
import {
  Artist,
  SearchCategory,
  Filter,
  SoundKit,
  Beat,
  Tag,
  SubGenre,
  Mood,
  SocialMediaType,
  Genre,
  MarketplaceSearchCategory,
} from "../models/models";
import { Option } from "../models/sorting-filtering";

export const socialMediaLL: SocialMediaType = {
  soundcloud: "https://soundcloud.com/joinlutely",
  youtube: "https://www.youtube.com/@joinlutely",
  tiktok: "https://www.tiktok.com/@joinlutely",
  facebook: "https://www.facebook.com/joinlutely/",
  instagram: "https://www.instagram.com/joinlutely/?hl=en",
  twitter: "https://twitter.com/joinlutely",
  x: "https://x.com/joinlutely",
};

export const tags = {
  new: {
    name: "New",
    textColor: "text-purple",
    backgroundColor: "bg-softPurple",
  } as Tag,
  trending: {
    name: "Trending",
    textColor: "text-inspired",
    backgroundColor: "bg-softOrange",
  } as Tag,
  featured: {
    name: "Featured",
    textColor: "text-love",
    backgroundColor: "bg-softPink",
  } as Tag,
};

export const artists: Artist[] = [
  {
    id: "1",
    name: "Jenny Wilson",
    profilePicUrl: "/assets/images/newsletter/top-right.jpg",
    location: {
      city: "New Jersey",
      state: "California",
    },
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
  },
  {
    id: "2",
    name: "Hola",
    profilePicUrl: "/assets/images/newsletter/bottom-right.jpg",
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
    location: {
      city: "New Jersey",
      state: "California",
    },
  },
  {
    id: "3",
    name: "Jenny Wilson",
    profilePicUrl: "/assets/images/newsletter/top-left.jpg",
    location: {
      city: "New Jersey",
      state: "California",
    },
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
  },
  {
    id: "4",
    name: "Jenny Wilson",
    profilePicUrl: "/assets/images/newsletter/center-left.jpg",
    location: {
      city: "New Jersey",
      state: "California",
    },
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
  },
  {
    id: "5",
    name: "Jenny Wilson",
    profilePicUrl: "/assets/images/newsletter/bottom-left.jpg",
    location: {
      city: "New Jersey",
      state: "California",
    },
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
  },
  {
    id: "6",
    name: "Jenny Wilson",
    profilePicUrl: "/assets/images/cover6.jpeg",
    location: {
      city: "New Jersey",
      state: "California",
    },
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
  },
  {
    id: "7",
    name: "Jenny Wilson",
    profilePicUrl: "/assets/images/cover7.jpeg",
    location: {
      city: "New Jersey",
      state: "California",
    },
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
  },
  {
    id: "8",
    name: "Jenny Wilson",
    profilePicUrl: "/assets/images/cover8.jpeg",
    location: {
      city: "New Jersey",
      state: "California",
    },
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
  },
  {
    id: "9",
    name: "Jenny Wilson",
    profilePicUrl: "/assets/images/cover9.webp",
    location: {
      city: "New Jersey",
      state: "California",
    },
    firstName: "jaja",
    lastName: "jakala",
    bio: "",
    type: "creators",
    createdAt: "",
  },
];

export const beats: Beat[] = [
  {
    id: "1",
    name: "Tarzan song",
    artist: artists[0],
    cover: "/assets/images/cover1.jpeg",
    duration: 200,
    isMp3: false,
    genres: {} as Genre,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.new,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
    pictureUrl: "/assets/images/cover1.jpeg",
  },
  {
    id: "2",
    name: "Take on me",
    artist: artists[6],
    cover: "/assets/images/cover2.jpeg",
    pictureUrl: "/assets/images/cover2.jpeg",
    duration: 200,
    isMp3: false,
    genres: {} as Genre,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.new,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "3",
    name: "Hungry like the wolf",
    artist: artists[5],
    cover: "/assets/images/cover3.webp",
    pictureUrl: "/assets/images/cover3.webp",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.trending,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "4",
    name: "Thriller",
    artist: artists[1],
    cover: "/assets/images/cover4.webp",
    pictureUrl: "/assets/images/cover4.webp",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.trending,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "5",
    name: "Land of confusion",
    artist: artists[2],
    cover: "/assets/images/cover5.webp",
    pictureUrl: "/assets/images/cover5.webp",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.featured,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "6",
    name: "Everybody wants to rule the world",
    artist: artists[7],
    cover: "/assets/images/cover6.jpeg",
    pictureUrl: "/assets/images/cover6.jpeg",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.featured,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "7",
    name: "Song Name",
    artist: artists[7],
    cover: "/assets/images/cover7.jpeg",
    pictureUrl: "/assets/images/cover7.jpeg",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.trending,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "8",
    name: "Song Name",
    artist: artists[2],
    cover: "/assets/images/cover8.jpeg",
    pictureUrl: "/assets/images/cover8.jpeg",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.new,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "9",
    name: "Song Name",
    artist: artists[1],
    cover: "/assets/images/cover9.webp",
    pictureUrl: "/assets/images/cover9.webp",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.trending,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "10",
    name: "Song Name",
    artist: artists[1],
    cover: "/assets/images/cover10.jpeg",
    pictureUrl: "/assets/images/cover10.jpeg",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.featured,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "11",
    name: "Song Name",
    artist: artists[4],
    cover: "/assets/images/cover11.webp",
    pictureUrl: "/assets/images/cover11.webp",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.featured,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
  {
    id: "12",
    name: "Song Name",
    artist: artists[3],
    cover: "/assets/images/cover12.jpeg",
    pictureUrl: "/assets/images/cover12.jpeg",
    genres: {} as Genre,
    duration: 200,
    isMp3: false,
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    tempo: 233,
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.featured,
    sample: "/assets/mp3/sample.mp3",
    price: 9.34,
    type: "beats",
    licenseRights: [],
  },
];

export const soundkits: SoundKit[] = [
  {
    id: "1",
    name: "Drum kit Vol. 1",
    description: "This kit includes 20 drum sounds and 59 something",
    cover:
      "https://photographylife.com/wp-content/uploads/2023/05/Nikon-Z8-Official-Samples-00002.jpg",
    artist: artists[0],
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.featured,
    sample: "/assets/mp3/sample.mp3",
    price: 8.29,
    type: "sounds",
    tempo: 383,
  } as SoundKit,
  {
    id: "2",
    name: "Drum kit Vol. 1",
    description: "This kit includes 20 drum sounds and 59 something",
    cover: "/assets/images/cover2.jpeg",
    artist: artists[1],
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.featured,
    sample: "/assets/mp3/sample.mp3",
    price: 8.29,
    type: "sounds",
    tempo: 383,
  } as SoundKit,
  {
    id: "3",
    name: "Drum kit Vol. 1",
    description: "This kit includes 20 drum sounds and 59 something",
    cover: "/assets/images/cover3.webp",
    artist: artists[2],
    styles: [{ name: "Rap", id: "1" } as SubGenre],
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.trending,
    sample: "/assets/mp3/sample.mp3",
    price: 8.29,
    type: "sounds",
    tempo: 383,
  } as SoundKit,
  {
    id: "4",
    name: "Drum kit Vol. 1",
    description: "This kit includes 20 drum sounds and 59 something",
    cover: "/assets/images/cover4.webp",
    artist: artists[3],

    styles: [{ name: "Rap", id: "1" } as SubGenre],
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.new,
    sample: "/assets/mp3/sample.mp3",
    price: 8.29,
    type: "sounds",
    tempo: 383,
  } as SoundKit,
  {
    id: "5",
    name: "Drum kit Vol. 1",
    description: "This kit includes 20 drum sounds and 59 something",
    cover: "/assets/images/cover5.webp",
    artist: artists[4],

    styles: [{ name: "Rap", id: "1" } as SubGenre],
    moods: [{ name: "Happy", id: "1" } as Mood],
    tag: tags.featured,
    sample: "/assets/mp3/sample.mp3",
    price: 8.29,
    type: "sounds",
    tempo: 383,
  } as SoundKit,
];

export const options = [
  { id: 1, name: "Option 1" },
  { id: 2, name: "Option 2" },
  { id: 3, name: "Option 3" },
  { id: 4, name: "Option 4" },
  // More options...
];

export const discoverOptions = [
  { id: 1, name: "Beats" },
  { id: 2, name: "Soundkits" },
  { id: 3, name: "Creators" },
  { id: 4, name: "Songs" },
];

export const baseSortOptions: Option[] = [
  {
    id: 2,
    name: "Newest",
  },
  {
    id: 3,
    name: "Oldest",
  },
  {
    id: 4,
    name: "Lowest Price",
  },
  {
    id: 5,
    name: "Highest Price",
  },
  {
    id: 6,
    name: "Sort A to Z",
  },
  {
    id: 7,
    name: "Sort Z to A",
  },
];

export const sortOptions = [
  {
    id: 1,
    name: "Random",
    value: Math.random() * 50,
  },
  ...baseSortOptions,
];

export const formats = ["MP3", "WAV", "STEMS + WAV"];

export const packages: string[] = [
  "Release your song anywhere online (social media and dsp's)",
  "For profit performances",
  "Radio broadcast rights",
  "Create and publish 1 music video",
];

export const addOns = [
  { id: "1", name: "Monetize your music video" },
  // { id: '3', name: 'Royalty Free' },
  // { id: '2', name: 'Promotion from Artist' },
  // { id: '4', name: 'Add Publishing' },
];

export const partners = [
  "/assets/svg/vh1.svg",
  "/assets/svg/nickelodeon.svg",
  "/assets/svg/verizon.svg",
  "/assets/svg/ea-sports.svg",
];

export const licensingTypes: Filter[] = [
  { id: "0", name: "Any", type: FilterType.licenseType, description: "" },
  {
    id: "1",
    name: "Exclusive",
    type: FilterType.licenseType,
    description: "Lorem ipsum dolor sit amet consectetur. Amet nisl dolor",
  },
  {
    id: "2",
    name: "Recording",
    type: FilterType.licenseType,
    description: "Lorem ipsum dolor sit amet consectetur. Amet nisl dolor",
  },
  {
    id: "3",
    name: "Sync",
    type: FilterType.licenseType,
    description: "Lorem ipsum dolor sit amet consectetur. Amet nisl dolor",
  },
  {
    id: "4",
    name: "Royalty Free",
    type: FilterType.licenseType,
    description: "",
  },
];

// export const soundsLicensingTypes: Filter[] = [
//     { id: '1', name: "Any", type: FilterType.licenseType, description: '', },
//     { id: '2', name: "Royalty", type: FilterType.licenseType, description: '', },
// ];

export const kinds: Filter[] = [
  { id: "kind-any", name: "Any", type: FilterType.kindsType },
  { id: "kind-one-shots", name: "One Shots", type: FilterType.kindsType },
  { id: "kind-loops", name: "Loops", type: FilterType.kindsType },
  { id: "kind-vocals", name: "Vocals", type: FilterType.kindsType },
  { id: "kind-kits", name: "Construction Kits", type: FilterType.kindsType },
  { id: "kind-fx", name: "FX", type: FilterType.kindsType },
  { id: "kind-presets", name: "Presets", type: FilterType.kindsType },
];

export const stems = ["Either", "Yes", "No"];

export const allStems: Filter[] = [
  {
    id: "stem-guitar",
    name: "Guitar",
    type: FilterType.stemsType,
  },
  {
    id: "stem-bass",
    name: "Bass",
    type: FilterType.stemsType,
  },
  {
    id: "stem-drum",
    name: "Drums",
    type: FilterType.stemsType,
  },
  {
    id: "stem-vocal",
    name: "Vocals",
    type: FilterType.stemsType,
  },
  {
    id: "stem-piano",
    name: "Piano",
    type: FilterType.stemsType,
  },
  {
    id: "stem-other",
    name: "Other",
    type: FilterType.stemsType,
  },
];

export const rights: Filter[] = [
  { id: "1", name: "Any", type: FilterType.rightsType },
  { id: "2", name: "Exclusive", type: FilterType.rightsType },
  { id: "3", name: "Non-Exclusive", type: FilterType.rightsType },
  // { id: '4', name: "Buy-Out", type: FilterType.rightsType },
];

export const trackTypes = [
  { id: "1", name: "Beats", type: FilterType.trackType },
  // { id: '2', name: "Beats with Chorus", type: FilterType.trackType },
  // { id: '3', name: "Songs", type: FilterType.trackType },
  { id: "4", name: "Sounds", type: FilterType.trackType },
];

export const recency = [
  { id: "date-any", name: "Any", type: FilterType.recencyType },
  { id: "date-60d", name: "Last 60 days", type: FilterType.recencyType },
  { id: "date-30d", name: "Last 30 days", type: FilterType.recencyType },
  { id: "date-7d", name: "Last 7 days", type: FilterType.recencyType },
  { id: "date-2d", name: "Last 2 days", type: FilterType.recencyType },
  { id: "date-1d", name: "Today", type: FilterType.recencyType },
] as Filter[];

export const collections = [
  {
    name: "Collection 1",
    soundkits: soundkits.slice(0, 4),
  },
  {
    name: "Collection 2",
    soundkits: soundkits.slice(1, 3),
  },
  {
    name: "Collection 3",
    soundkits: soundkits.slice(2, 5),
  },
  {
    name: "Collection 4",
    soundkits: soundkits.slice(1, 5),
  },
  {
    name: "Collection 5",
    soundkits: soundkits.slice(0, 4),
  },
];

export const searchCategories: SearchCategory[] = [
  {
    name: "Beats",
    icon: Headphones,
    backgroundColor: "#1DF364",
    color: "#000",
  },
  {
    name: "Sounds",
    icon: Soundkits,
    backgroundColor: "#ff8544",
    color: "#000",
  },
  { name: "Songs", icon: Song, backgroundColor: "#2cc6ff", color: "#fff" },
  { name: "Creators", icon: User, backgroundColor: "#4D89FF", color: "#fff" },
];

export const marketplaceSearchCategories: MarketplaceSearchCategory[] = [
  {
    id: "all",
    name: "All",
    icon: null,
  },
  {
    id: "beats",
    name: "Beats",
    icon: Headphones,
  },
  {
    id: "songs",
    name: "Songs",
    icon: Song,
  },
  {
    id: "sounds",
    name: "Sounds",
    icon: Soundkits,
  },
];

export const recentSearches: string[] = [
  "Piano Vibes",
  "New Music",
  "Alternative",
];

export const productInfoItems = [
  {
    title: "Genres:",
    text: "East Coast, Hip Hop",
    icon: GridFill,
  },
  {
    title: "Mood:",
    text: "Excited, Hyped",
    icon: Track,
  },
  {
    title: "Sounds Like:",
    text: "Pusha T",
    icon: Star,
  },
  {
    title: "BPM:",
    text: "87",
    icon: Note,
  },
  {
    title: "Key signature:",
    text: "B",
    icon: KeyIcon,
  },
];
