import FilledButton from "@/components/buttons/filled-button";
import TransparentButton from "@/components/buttons/transparent-button";
import NavigationDropdown from "@/components/dropdown/navigation-dropdown";
import Icon from "@/components/icons/icon";
import { authenticatedLinks } from "@/data/navbar-links";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useLogoutConfirmationModal } from "./state";
import BaseSkeleton from "@/components/base-skeleton";
import {
  useGetProfileTimestampContext,
  useProfileValidPicture,
} from "@/lib/atoms/profile";
import ArtistPicker from "./ArtistPicker";
import User from "@/components/icons/lib/user";
import { isNil } from "lodash";

const AuthButtons = () => {
  const router = useRouter();
  const session = useSession();
  const [_, setLogoutConfirmationModal] = useLogoutConfirmationModal();
  const timestamp = useGetProfileTimestampContext();
  const [isValidProfilePic] = useProfileValidPicture();

  if (session?.status === "loading" || !isNil(session.data?.error)) {
    return <BaseSkeleton className="pl-6 w-[28px] h-[28px] rounded-full" />;
  }

  if (session?.status === "authenticated" && isNil(session.data?.error)) {
    const handledAuthLinks = authenticatedLinks.map((link) => ({
      ...link,
      onClick:
        link.id === "account-link-logout"
          ? () => {
              setLogoutConfirmationModal(true);
            }
          : undefined,
    }));

    return (
      <>
        {(!session.data.user.hasActiveSubscription ||
          session.data.user?.subscription?.provider === "legacy") && (
          <Link href="/account/upgrade">
            <FilledButton
              variant="primary"
              decoration="outlinedTest"
              className="font-righteous text-black mr-5"
            >
              <span>Upgrade</span>
            </FilledButton>
          </Link>
        )}

        {session.data.user.isLabelAccount && (
          <ArtistPicker />
        )}

        <NavigationDropdown
          options={handledAuthLinks
            .map((link) => {
              if (link.name === "Profile" && session?.data?.user.email) {
                return { ...link, name: session.data.user.email };
              }
              return link;
            })
            .filter((link) =>
              session?.data?.user && link.visible
                ? link.visible(session?.data?.user)
                : true
            )}
          position="bottom-left"
          image={
            isValidProfilePic && session?.data?.user?.profilePicUrl
              ? `${session?.data?.user.profilePicUrl}?timestamp=${timestamp}`
              : null
          }
          className="font-bold hidden lg:flex pl-6"
        >
          {!isValidProfilePic && (
            <div className="w-7 h-7">
              <div className="flex justify-center items-center h-full w-full bg-gradient-to-br from-purple to-green p-[25%] rounded-full">
                <Icon icon={User} color="white" className="h-full w-full" />
              </div>
            </div>
          )}
        </NavigationDropdown>
      </>
    );
  } else {
    return (
      <>
        <Link href="/auth/login">
          <TransparentButton
            color="white"
            className="flex flex-row items-center gap-[10px] px-5 ms-5"
            id="navbar-login-button"
          >
            <span className="font-righteous">Log In</span>
            <ChevronRightIcon className="flex-shrink-0 w-[20px]" />
          </TransparentButton>
        </Link>
        <Link href="/auth/sign-up">
          <FilledButton
            variant="primary"
            decoration="outlinedTest"
            className="font-righteous text-black"
          >
            <span>Sign up</span>
          </FilledButton>
        </Link>
      </>
    );
  }
};

export default AuthButtons;
