import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import FourSquaresIcon from "../icons/four-squares";
import { Testimonial } from "@/lib/types/testimonials";
import SignupCtaButton from "./signup-cta-button";
import TestimonialsCarousel from "./testimonials-carousel";

export type SignupAndTestimonialsProps = {
  testimonials: Testimonial[];
  changeInterval: number;
} & ClassProps;

const SignupAndTestimonials = ({
  className,
  testimonials,
  changeInterval,
}: SignupAndTestimonialsProps) => {
  return (
    <div className={cx("flex flex-col items-center relative", className)}>
      {/* Signup section */}
      <div className="flex flex-col items-center gap-[10px] py-[120px] z-20">
        <h2 className="font-righteous text-center text-[54px] leading-[110%] text-darkGreen">
          Sign Up for <span className="text-green">FREE</span>
        </h2>

        <p>or go unlimited for just $15/month.</p>

        <SignupCtaButton />
      </div>

      {/* Testimonials */}
      <div className="flex flex-col items-center gap-10 py-[60px]">
        <h2 className="font-righteous text-center text-[54px] leading-[110%] text-darkGreen z-20">
          People Love Lutely
        </h2>

        <TestimonialsCarousel
          testimonials={testimonials}
          changeInterval={changeInterval}
          className="z-20"
        />
      </div>

      {/* Bg image */}
      <FourSquaresIcon className="absolute -left-[260px] top-10 z-0" />
    </div>
  );
};

export default SignupAndTestimonials;
