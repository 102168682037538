import React from "react";
import FourSquaresIcon from "../icons/four-squares";
import SuperchargeYourMusicCtaButton from "./supercharge-your-music-cta-button";

const SuperchargeYourMusic = () => {
  return (
    <div className="flex flex-col items-center gap-5 relative py-[120px] overflow-hidden">
      <h4 className="font-righteous text-[34px] leading-[110%] z-20 text-center">
        Ready to supercharge your music?
      </h4>

      <p className="z-20 text-center">
        Get started for free or go unlimited for just $15/month.
      </p>

      <SuperchargeYourMusicCtaButton className="z-20" />

      <FourSquaresIcon className="absolute -left-[50px] -bottom-[200px] z-10" />

      <FourSquaresIcon className="absolute -right-[80px] -top-[200px] z-10 hidden md:inline-block" />
    </div>
  );
};

export default SuperchargeYourMusic;
